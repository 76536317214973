import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-row',
  styleUrls: ['./row.component.scss'],
  templateUrl: './row.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class RowComponent implements OnInit {
  @Input() align:
    | 'start'
    | 'center'
    | 'end'
    | 'baseline'
    | 'stretch'
    | undefined;
  @Input() justify:
    | 'start'
    | 'center'
    | 'end'
    | 'between'
    | 'around'
    | 'evenly'
    | undefined;
  @Input() noGutter: boolean = false;
  @HostBinding('class') classes: string[] = [];

  constructor() {}

  ngOnInit(): void {
    this.align && this.classes.push(`align-${this.align}`);
    this.justify && this.classes.push(`justify-${this.justify}`);
    this.noGutter && this.classes.push('no-gutter');
  }
}
