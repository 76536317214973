import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-col',
  styleUrls: ['./col.component.scss'],
  templateUrl: './col.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class ColComponent implements OnInit {
  @Input() xs: ColSize;
  @Input() sm: ColSize;
  @Input() md: ColSize;
  @Input() lg: ColSize;
  @Input() xl: ColSize;
  @Input() hide: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  @Input() show: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  @HostBinding('class') classes: string[] = [];

  ngOnInit(): void {
    this.classes.push('col-base');
    this.xs && this.classes.push(`col-xs-${this.xs}`);
    this.sm && this.classes.push(`col-sm-${this.sm}`);
    this.md && this.classes.push(`col-md-${this.md}`);
    this.lg && this.classes.push(`col-lg-${this.lg}`);
    this.xl && this.classes.push(`col-xl-${this.xl}`);
    this.hide && this.classes.push(`${this.hide}-hide`);
    this.show && this.classes.push(`${this.show}-show`);
  }
}

type ColSize =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | undefined;
